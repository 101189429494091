// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client';
import {environmentConfig} from "@/config/enviromentConfig";

// eslint-disable-next-line no-unused-vars

export class SocketHandler {
  /** @type String */
  _roomId;

  /** @type String[][] */
  _boardArray;

  /**
   * @param {String} phoneNo
   */
  constructor(phoneNo) {
    // const USER_ID = new Date().getTime();
    const URL = `${environmentConfig.websocketHost}?phone-no=${phoneNo}`;

    this.socket = io(URL, { transports: ['websocket'] });
  }

  registerListener(event, cb) {
    this.socket.on(event, cb.bind(this));
  }

  unRegisterListener(event, cb) {
    this.socket.off(event, cb.bind(this));
  }

  onConnect() {}

  disconnectConnection() {
    this.socket.disconnect();
  }

  onDisconnect() {}
}
