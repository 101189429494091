<template>
  <div
    v-bind:style='backgroundImage'
    class='align-items-center d-flex flex-column intro-main position-relative text-black'
  >
    <div class='d-flex flex-column-reverse intro-container overflow-y-auto w-75 z-1'>
      <transition-group name='list' tag='div' mode='out-in'>
        <div
          v-for='{ name, index } in TextPrompts'
          :key='index'
          :class='`w-100 mt-4 ${changeMessageClass(index)}`'
        >
          <p :class='`fs-6 mx-0 my-0 ${changeNameColor(name)}`'>{{ name }}:</p>
          <p class='chat-text'>{{ translationsFormatter(`tutorial_prompt_${index + 1}`) }}</p>
        </div>
      </transition-group>
    </div>

    <div
      id='img-div'
      class='align-items-start bottom-0 d-flex position-absolute w-100'
      style='height: 50%'
    >
      <img :src="tutorialScreenElements['rhinoChetaLion']" alt='' class='h-100 w-100' />

      <button
        @click='tapToContinueBtn'
        type='button'
        class='fs-6 position-absolute start-50 tap-continue-btn text-white translate-middle-x'
      >
        {{ translationsFormatter('tap_button_text') }}
      </button>
    </div>
    <button
      @click='skipTutorial'
      class='position-absolute bottom-0 w-100'
      type='button'
      style='height: 2.5rem'
    >
      {{ translationsFormatter('skip_tutorial_text') }}
    </button>
  </div>
</template>

<script>
  import { staticImages, dynamicBg } from '../../../constants/imageUrlPath';
  import { textPrompts } from '../../../constants/tutorialsChatData';
  import { mapState } from 'vuex';

  export default {
    name: 'IntroScreenUi',
    data() {
      return {
        TextPrompts: [textPrompts[0]],
        imageStackIndex: 1,
        tutorialScreenElements: {
          rhinoChetaLion: staticImages.rhinoChetaLion,
        },
      };
    },

    methods: {
      tapToContinueBtn() {
        if (this.imageStackIndex == textPrompts.length) {
          const phone = this.$route.query.phone || this.user.msisdn;
          this.$router.push({ name: 'firstgame', query: { phone } });
        } else {
          this.stackImages();
        }
      },
      stackImages() {
        const newImage = textPrompts[this.imageStackIndex];
        this.TextPrompts.push(newImage);
        this.imageStackIndex++;
      },
      skipTutorial() {
        const phone = this.$route.query.phone || this.user.msisdn;
        this.$router.push({ name: 'submitusername', query: { phone } });
      },
      changeNameColor(name) {
        switch (name) {
          case 'chira':
            return 'chiraColor';
          case 'lionel':
            return 'lionelColor';
          case 'brutis':
            return 'brutisColor';
        }
      },
      changeMessageClass(index) {
        const arr = [1, 3, 6, 8, 9];
        if (arr.includes(index)) {
          return 'right-chat-box';
        } else {
          return 'chat-box';
        }
      },
    },

    computed: {
      ...mapState(['user']),
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
        };
      }
    },
    unmounted() {
      this.TextPrompts = [];
    },
  };
</script>

<style lang='css'>
  @import './index.css';
</style>
