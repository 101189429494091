<template>
  <div v-bind:style='backgroundImage' class='signup-main'>
    <div class='h-25 w-75'>
      <img :src="signUpScreenElements['logo']" alt='' srcset='' class='h-100 w-100' />
    </div>
    <input v-if="brandConfig.brand !== 'demo-en'" class='msisdn-input text-center' v-model='msisdn'
           :placeholder="`${translationsFormatter('number_input_text')}`">
    <div class='d-flex flex-column h-25 w-75'>
      <button
        @click='confirmClicked()'
        @keypress.enter='confirmClicked()'
        class='btn fs-3 h-50 mt-4 text-light'
      >
        {{ translationsFormatter('confirm_button') }}
      </button>
      <div class='d-flex flex-column align-items-center text-center error-text' v-if=!isMsisdnInputValid>
        {{ translationsFormatter('no_subscription_text') }}
      </div>
    </div>
<!--    <button @click='subscribeBtnClicked()' class='subscribe-btn' v-if='!isMsisdnInputValid'>-->
<!--      {{ translationsFormatter('subscribe_text') }}-->
<!--    </button>-->
    <SpinnerUi v-if='showSpinner' />
  </div>
</template>

<script>
  import SpinnerUi from '../SpinnerUi';
  import { staticImages, dynamicBg } from '@/constants/imageUrlPath';
  import { mapState, mapMutations } from 'vuex';
  import AisService from '@/services/aisService';
  import { getUserByPhoneNumber } from '@/handlers/apis';

  export default {
    name: 'MsisdnInputUi',
    components: {
      SpinnerUi,
    },
    data() {
      return {
        isButtonDisabled: true,
        showSpinner: false,
        textColor: 'text-black',
        signUpScreenElements: staticImages,
        msisdn: undefined,
        isMsisdnInputValid: true,
      };
    },
    methods: {
      ...mapMutations(['setUser']),
      async confirmClicked() {
        if(this.brandConfig.brand === 'demo-en'){
          this.msisdn = this.brandConfig.msisdn;
        }
        const user = await AisService.getUserViaProductId(this.brandConfig.productId, this.msisdn);
        const mmrUser = await getUserByPhoneNumber(this.msisdn);
        if (user.statusName === 'Active') {
          this.isMsisdnInputValid = true;
          this.setUserData(user, mmrUser)
          await AisService.trackAisUserLogin(this.brandConfig.productId, this.msisdn);
          this.$router.push({ name: 'home' });
        } else {
          this.isMsisdnInputValid = false;
        }
      },
      setUserData(user, mmrData){
        this.setUser(Object.assign(user, mmrData));
        localStorage.setItem('userData', JSON.stringify(user));
      },
      subscribeBtnClicked() {
        window.open(`https://facebook.com`, '_self');
      },
    },
    computed: {
      ...mapState(['brandConfig']),
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
        };
      },
    },
    mounted() {
      if (this.$DYNAMIC_BG === 'nigeria1' || this.$DYNAMIC_BG === 'nigeria3')
        this.textColor = 'text-white';
    },
  };
</script>

<style lang='css'>
  @import './index.css';

  .msisdn-input {
    margin-top: 50px;
    height: 65px;
    width: 75%;
    border-radius: 12px;
    font-size: 20px;
  }

  ::placeholder {
    font-size: 20px;
  }

  .error-text {
    margin-top: 16px;
    font-size: 18px;
    color: red;
  }

  .subscribe-btn {
    height: 45px;
    width: 200px;
    font-size: 20px;
    border-radius: 10px;
  }
</style>
