import store from '@/store';
import {getUserByPhoneNumber} from '@/handlers/apis';
import AisService from '@/services/aisService';
import {serviceIdToPackageMap} from "@/config/enviromentConfig";
import {Brands} from "@/brand/brandConfig";
import DepService from "@/services/depService";

export async function userAuthenticate(to, from, next) {
    const {brand} = store.state.brandConfig;
    const {productId} = store.state.brandConfig;
    switch (brand) {
        case Brands.MTNNigeriaEn: {
            return userAuthenticateNG(to, from, next, productId);
        }
        case Brands.UpstreamAISTh: {
            return await userAuthenticateAis(to, from, next, productId);
        }
        case Brands.DemoEn: {
            return await userAuthenticateAis(to, from, next, productId);
        }
        default:
            return await userAuthenticateAis(to, from, next, productId);
    }
}

async function userAuthenticateAis(to, from, next) {
    if (from.fullPath === '/') return next();
    if (store.state.user) return next();
    if (to.name === 'signup' || to.name === 'error' || to.name === 'termsConditions') return next();

    const urlParams = new URLSearchParams(window.location.search);
    const msisdn = urlParams.get('msisdn') || store.state.user.msisdn;

    if (msisdn) return await msisdnLoginAuth(msisdn, urlParams, next);
}

async function msisdnLoginAuth(msisdn, urlParams, next) {
    await setUserData(msisdn, urlParams);
    return redirectBasedOnUserStatus(store.state.user, msisdn, next);
}

async function setUserData(msisdn, urlParams) {
    const user = await AisService.getUserViaProductId(store.state.brandConfig.productId, msisdn);
    const mmrUser = await getUserByPhoneNumber(msisdn);

    const userData = {
        channel: urlParams.get('channel'),
        extRef: urlParams.get('ext_ref'),
        msisdn: msisdn,
        result: urlParams.get('result'),
        resultId: urlParams.get('result_id'),
        subscriptionId: urlParams.get('subscription_id'),
    };

    localStorage.setItem('userData', JSON.stringify(user));
    store.commit('setUser', Object.assign(userData, mmrUser));
    store.commit('setMsisdn', msisdn);
}

function setNGUser() {
    const urlParams = new URLSearchParams(window.location.search);
    const userData = {
        channel: urlParams.get('channel'),
        extRef: urlParams.get('ext_ref'),
        msisdn: urlParams.get('msisdn'),
        result: urlParams.get('result'),
        resultId: urlParams.get('result_id'),
        subscriptionId: urlParams.get('subscription_id'),
        serviceId: urlParams.get('service_id'),
        package: getPackageDetails(urlParams.get('service_id'))
    };

    localStorage.setItem('userData', JSON.stringify(userData));
    return userData;
}

function getPackageDetails(serviceId) {
    if (serviceIdToPackageMap.has(serviceId?.toString())) {
        return serviceIdToPackageMap.get(serviceId);
    } else return serviceIdToPackageMap.get(`default`);
}

function redirectBasedOnUserStatus(userData, msisdn, next) {
    return isUserResultValid(userData.result) ? next({name: 'home', query: {msisdn}}) : next({name: 'error'});
}

function isUserResultValid(userResult) {
    return userResult === 'ACTIVE' || userResult === 'CONFIRMED';
}

async function userAuthenticateNG(to, from, next, productId) {
    if (from.fullPath === '/' && to.path === `/login`) {
        const userData = setNGUser();
        const msisdn = userData.msisdn;
        return await allowUserAccessNG(userData, productId) ? next({name: 'home', query: {msisdn}}) : next({name: 'error'});
    }
    if (from.fullPath === '/') return next();
    if (store.state.user) return next();
    if (to.name === 'signup' || to.name === 'error' || to.name === 'termsConditions') return next();
}

async function allowUserAccessNG(userData, productId) {
    const {user} =  await DepService.getUserViaProductId(productId, userData.msisdn);
    const validUser = user.statusName === 'ACTIVE' || user.statusName === 'CONFIRMED';
    if(validUser){
        const mmrData = await getUserByPhoneNumber(userData.msisdn);
        store.state.user = (Object.assign(user, mmrData));
        localStorage.setItem('userData', JSON.stringify(user));
    }
    return validUser;
}
