export const translations = {
  'game_begins_in': 'เกมเริ่มต้นใน',
  'subscribe_text': 'สมัคร',
  'no_subscription_text': 'ไม่พบการสมัครสมาชิก',
  'number_input_text': 'กรอกหมายเลขโทรศัพท์มือถือ',
  'login_playing_button': 'เริ่มเล่น',
  'login_term_conditions_daily_text': 'บริการนี้มีค่าใช้จ่าย x/วัน บริการสมัครสมาชิก เป็นไปตามข้อกำหนดและเงื่อนไข',
  'login_info_text': 'stand a chance to win airtime & other great prizes. Enter your ceLL number below to start playing.',
  'login_error_text': 'oops! there was a problem with logging you in!',
  'login_try_again_text': 'รวบรวมเหรียญ',
  'loading_text': 'กำลังโหลด',
  'play_button': 'เล่น',
  'back_button': 'กลับ',
  'play_again_button': 'เล่นอีกครั้ง',
  'home_button': 'หน้าหลัก',
  'confirm_button': 'ยืนยัน',
  'coins_text': 'เหรียญ',
  'rules_header_text': 'กฎและวิธีเล่น',
  'rules_point_one': 'เก็บ 50 เหรียญในแต่ละวันได้ฟรี',
  'rules_point_two': 'ใช้ 10 เหรียญต่อเกม (พร้อมโอกาสในการชิงเหรียญของคู่แข่งหากคุณชนะ)',
  'rules_point_three': 'ต่อสู้กับผู้เล่นแบบออนไลน์และรับคะแนนให้ได้มากที่สุด',
  'rules_point_four': 'ผู้เล่นแต่ละคนจะมี 8 ตา ตาละ 15 วินาทีเพื่อรับคะแนนให้ได้มากที่สุด',
  'rules_point_five': 'เล่นกระดานเดียวกันกับคู่แข่งและดูการขยับของพวกเขาแบบเรียลไทม์',
  'coin_collect_text': 'รวบรวมเหรียญ',
  'coin_tomorrow_text': 'กลับมาเล่นอีกในวันพรุ่งนี้เพื่อรับอีก 50 เหรียญ',
  'coin_earned_text': 'คุณได้รับ',
  'tutorial_prompt_1': 'เฮ้! มีคนขโมยเหรียญฉันไป และฉันต้องไปเอาคืนมา คุณช่วยฉันได้ไหม',
  'tutorial_prompt_2': `สวัสดี! ยินดีต้อนรับสู่ Mega Match Rivals! ยินดีอย่างยิ่งที่ได้พบคุณ! ฉันและ Chira ต้องการให้คุณช่วย`,
  'tutorial_prompt_3': 'โอ้ คุณต้องการเหรียญคืนหรือ เอาล่ะ ฉันมีความคิดดีๆ แล้ว...',
  'tutorial_prompt_4': 'ฉันรู้ว่าคุณเป็นคนเอาไป! เอาคืนมานะ!',
  'tutorial_prompt_5': 'เอาแบบนี้ไหม... มาเล่นเกมกัน ถ้าคุณชนะ ก็เอาเหรียญคืนไป แต่ถ้าฉันชนะ ฉันจะเก็บเหรียญไว้ต่อ',
  'tutorial_prompt_6': 'ไม่ต้องห่วง Chira ฉันจะช่วยเธอเอง ไปเอาเหรียญคืนมากันเถอะ',
  'tutorial_prompt_7': 'โปรดกำจัดเจ้าแรดดุร้ายนั่นและเอาเหรียญคืนมาให้ฉันที! ฉันทำด้วยตัวเองไม่ได้',
  'tutorial_prompt_8': 'คุณจะได้คะแนนด้วยการจับคู่อัญมณี 3 ชิ้นขึ้นไปแบบเรียงกัน! คุณมีเวลาเล่น 8 ตา แต่ละตาจะมีเวลา 15 วินาทีเพื่อที่จะทำคะแนนให้ได้มากที่สุด และต้องแข่งกับ Brutus ที่จะเล่นในกระดานเดียวกับคุณ!',
  'tutorial_prompt_9': 'ไปจัดการเขาเลย',
  'skip_tutorial_text': 'ข้ามบทช่วยสอน',
  'tap_button_text': 'แตะเพื่อดำเนินการต่อ',
  'prompt_name_text': 'เก่งมากที่เอาชนะ Brutus ได้! ตอนนี้ถึงเวลาเจอของจริงแล้ว...แต่อันดับแรก ช่วยบอกชื่อของคุณหน่อยสิ ',
  'create_username_text': 'สร้างบัญชีผู้ใช้',
  'select_character_text': 'ะเลือกตัวละคร',
  'input_username_text': 'กรอกบัญชีผู้ใช้',
  'submit_button': 'ส่ง',
  'enter_game_text': 'คุณกำลังจะเข้าสู่เกมออนไลน์ ',
  'confirm_game_text': ' โปรดคลิก \'ยืนยัน\' เพื่อเริ่มค้นหาคู่แข่ง',
  'game_cost_text': 'เกมนี้จะใช้ ',
  'search_text': 'กำลังค้นหาคู่แข่ง',
  'you_text': 'คุณ',
  'select_text': 'เลือก',
  'powerups_text': 'เลือกไอเท็มเสริมพลัง 2 ชิ้น',
  'destroy_pieces_text': 'ทำลายอัญมณี 4 ชิ้น',
  'potential_moves_text': 'ดูการขยับที่เป็นไปได้',
  'double_points_text': 'คะแนนสองเท่า',
  'freeze_time_text': 'หยุดเวลา',
  'swap_pieces_text': 'สลับอัญมณีสองชิ้น',
  'reshuffle_text': 'สลับอัญมณีในกระดานใหม่',
  'match_begin_text': 'การแข่งขันจะเริ่มใน',
  'opponent_found_text': 'พบคู่แข่งแล้ว',
  'insufficient_coins_text': 'เหรียญไม่เพียงพอ คุณมีเหรียญไม่เพียงพอที่จะเล่นอีกครั้ง',
  'top_up_text': 'โปรดกลับมาเล่นอีกครั้งในวันพรุ่งนี้',
  'your_turn_text': 'ตาคุณแล้ว! เร็วเข้า จับคู่อัญมณีให้ได้มากที่สุด',
  'continue_button': 'ดำเนินการต่อ',
  'beat_brutis_text': 'เย้! คุณเอาชนะ Brutus และเอาเหรียญฉันคืนมาได้ ขอบคุณมากเลย!',
  'your_turn_loader_text': 'ตาของคุณ',
  'opponent_turn_loader_text': 'บคู่แข่องคุณ',
};
