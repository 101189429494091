<template>
  <div
    v-bind:style='backgroundImage'
    class='align-items-center d-flex flex-column rules-main'
  >
    <div class='mt-4 text-center text-shadow w-50'>
      <h1>{{ translationsFormatter('rules_header_text') }}</h1>
      <hr class='m-0 opacity-100' />
    </div>
    <div class='mt-4 text-black w-75'>
      <div class='d-flex w-100'>
        <p class='align-self-center w-75'>
          {{ translationsFormatter('rules_point_one') }}
        </p>
        <img :src='clockCoindBag' class='object-fit-contain width-30' />
      </div>

      <div class='d-flex mt-3 w-100'>
        <img :src='coinX10' class='object-fit-contain width-30' />
        <p class='align-self-center w-75 text-end'>
          {{ translationsFormatter('rules_point_two') }}
        </p>
      </div>

      <div class='d-flex mt-3 w-100'>
        <p class='align-self-center w-75'>
          {{ translationsFormatter('rules_point_three') }}
        </p>
        <img :src='youVsOpponent' class='object-fit-contain width-30' />
      </div>

      <div class='d-flex mt-3 w-100'>
        <img :src='seconds15Turns8' class='object-fit-contain width-30' />
        <p class='align-self-center w-75 text-end'>
          {{ translationsFormatter('rules_point_four') }}
        </p>
      </div>

      <div class='d-flex mt-3 w-100'>
        <p class='align-self-center w-75'>
          {{ translationsFormatter('rules_point_five') }}
        </p>
        <img :src='playSameBoard' class='object-fit-contain width-30' />
      </div>

      <div class='d-flex mt-3 w-100'>
        <img :src='playAirtime' class='object-fit-contain width-30' />
        <p class='align-self-center text-end w-75'>
          {{ translationsFormatter('rules_point_six') }}
        </p>
      </div>
    </div>
    <button @click='back' class='back-btn fs-4 mb-5 mt-3 text-white w-50'> {{ translationsFormatter('back_button') }}
    </button>
  </div>
</template>

<script>
  import { staticImages } from '@/constants/imageUrlPath';

  export default {
    name: 'RulesUi',
    methods: {
      back() {
        this.$router.go(-1);
      },
    },
    data() {
      return {
        seconds15Turns8: staticImages.seconds15Turns8,
        clockCoindBag: staticImages.clockCoindBag,
        coinX10: staticImages.coinX10,
        playAirtime: staticImages.playAirtime,
        playSameBoard: staticImages.playSameBoard,
        youVsOpponent: staticImages.youVsOpponent,
      };
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${staticImages['bgScreen02']})`,
        };
      },
    },
  };
</script>

<style lang='css'>
  @import './index.css';
</style>
