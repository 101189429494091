<template>
  <div v-bind:style="backgroundImage" class="justify-content-center confirm-main">
    <div
      class="align-items-center board-container d-flex flex-column justify-content-start w-75"
    >
      <img
        :src="gameConfirmScreenElements['singleCoin']"
        class="w-25"
        style="margin-top: -1rem"
      />
      <div class="p-4 pt-2 text-center" style='width: 85%' >
        {{ translationsFormatter('enter_game_text') }}
        <hr class="m-2 opacity-100" />
        {{ translationsFormatter('confirm_game_text') }}
        <hr class="m-2 opacity-0" />
        <div>{{ translationsFormatter('game_cost_text') }}</div>
        <div class="fs-2">10 {{ translationsFormatter('coins_text') }}</div>
      </div>
    </div>
    <button
      @click="navigateToOpponentSearch"
      type="button"
      class="confirmBtn fs-4 text-light mt-5"
    >
      {{ translationsFormatter('confirm_button') }}
    </button>
    <button @click="back" type="button" class="backBtn fs-4 text-light mt-3">
      {{ translationsFormatter('back_button') }}
    </button>
  </div>
</template>

<script>
  import { staticImages, dynamicBg } from '@/constants/imageUrlPath';
  import {mapMutations, mapState} from 'vuex';
  import {updateUser} from "@/handlers/apis";

  export default {
    name: 'GameConfirmationScreenUi',
    methods: {
      async navigateToOpponentSearch() {
        const phone = this.$route.query.phone || this.user.msisdn;
        await this.deductUserCoins();
        this.$router.push({ name: 'game', query: { phone } });
      },
      ...mapMutations(['setUser']),
      back() {
        this.$router.push({ name: 'home'});
      },
      async deductUserCoins(){
        const data = JSON.parse(localStorage.getItem('userData'));
        data.coinBalance -= 10;
        this.setUser(data);
        localStorage.setItem('userData', JSON.stringify(data));
        console.log(`GameConfirm User: ${JSON.stringify(data)}`);
        await updateUser(data);
      }
    },
    data() {
      return {
        gameConfirmScreenElements: {
          singleCoin: staticImages.singleCoin,
        },
      };
    },
    computed: {
      ...mapState(['user']),
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--board-backBtn-background': `url(${staticImages['board2']})`,
        };
      }
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
