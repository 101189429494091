import axios from 'axios';
import {environmentConfig} from "@/config/enviromentConfig";

export const createUser = async body => {
  return (await axios.post(`${environmentConfig.gameServerHost}/user`, body)).data;
};

export const getUserByPhoneNumber = async phone => {
  return (await axios.get(`${environmentConfig.gameServerHost}/user/${phone}`)).data;
};

export const updateUser = async body => {
  return (await axios.post(`${environmentConfig.gameServerHost}/user/upsertUser`, body)).data;
};
