import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class DepService {
    static async getUserViaProductId(productId, msisdn) {
        const response = await axios.post(`${environmentConfig.depHost}/get-user-via-msisdn`, {
            productId: productId,
            msisdn: msisdn,
        })
        return response.data;
    }

    static async trackUserLogin(msisdn, productId, service) {
        return await axios.post(`${environmentConfig.depHost}/track-user-login`, {
            productId: productId,
            msisdn: msisdn,
            serviceId: service === `daily` ? 6 : 7,
            isWeb: true,
        })
    }
}
