<template>
  <div
      v-bind:style='backgroundImage'
      class='align-items-center coin-collection-main d-flex flex-column justify-content-around text-shadow'
  >
    <div class='align-items-center d-flex flex-column position-relative'>
      <span class='fs-1 position-absolute' style='top: 2%'> {{ translationsFormatter('opponent_found_text') }}</span>
      <CoinCollectionAnimation/>
      <span class='fs-1 position-absolute top-50'
      ><number
          ref='number1'
          :from='0'
          :to='50'
          :format='theFormat'
          :duration='1.5'
          :delay='0.5'
          easing='Bounce.easeInOut'
      />
        {{ translationsFormatter('coins_text') }}</span
      >

      <div class='d-flex w-100 position-absolute' style='top: 90%'>
        <div class='align-items-center d-flex flex-column w-50'>
          <img
              :src="PlayerIconBlue[bluePlayerAvatar] || PlayerIconBlue['armadillo']"
              class='width-65'
          />
          <span class='fs-1'>{{ translationsFormatter('you_text') }}</span>
        </div>
        <div class='align-items-center d-flex flex-column w-50'>
          <img :src="PlayerIconRed['armadillo']" class='width-65'/>
          <span class='fs-1'>{{ redPlayerUsername }}</span>
        </div>
      </div>
    </div>
    <div class='d-flex flex-column text-center fs-1 lh-1'>
      {{ translationsFormatter('match_begin_text') }}<br/>
      3...2...1...
    </div>
  </div>
</template>

<script>
import {dynamicBg, PlayerIconBlue, PlayerIconRed} from '@/constants/imageUrlPath';
import CoinCollectionAnimation from '../CoinCollectionAnimation';
import {mapMutations, mapState} from "vuex";
import {updateUser} from "@/handlers/apis";

export default {
  name: 'CoinCollectionUI',
  components: {
    CoinCollectionAnimation,
  },
  data() {
    return {
      PlayerIconBlue: PlayerIconBlue,
      PlayerIconRed: PlayerIconRed,
      bluePlayerAvatar: '',
      redPlayerAvatar: '',
      redPlayerUsername: 'user359',
      showAwardCoinsAnimation: false
    };
  },
  methods: {
    navigateToGameConfirm() {
      const phone = this.$route.query.phone || this.user?.msisdn
      this.$router.push({name: 'gameconfirm', query: {msisdn: phone}});
    },
    navigateToIntroScreen() {
      this.$router.push({name: 'intro'});
    },
    navigateToInsufficientFunds() {
      this.$router.push({name: 'insufficientfunds'});
    },
    async updateUser(data) {
      await updateUser(data);
      data.updatedAt = new Date().toISOString();
      this.setUser(data);
      localStorage.setItem('userData', JSON.stringify(data));
    },
    ...mapMutations(['setUser']),
  },
  computed: {
    ...mapState(['user']),
    backgroundImage() {
      return {
        '--background-image': `url(${dynamicBg[0]})`,
      };
    },
  },
  async mounted() {
    if(this.user.coinBalance === 0) return this.navigateToInsufficientFunds();
    else if (!this.showAwardCoinsAnimation) {
      if (this.user?.userName) return this.navigateToGameConfirm();
      else return this.navigateToIntroScreen();
    } else {
      setTimeout(() => {
        return this.navigateToGameConfirm();
      }, 3000);
    }
  },
  async beforeMount() {
    const data = JSON.parse(localStorage.getItem('userData'));
    this.setUser(data);
    this.bluePlayerAvatar = data?.avatar;
    console.log(`User: ${JSON.stringify(this.user)}`);
    if (this.user?.userName && new Date(data?.updatedAt).toDateString() !== new Date().toDateString()) {
      //allocate coins daily.
      data.coinBalance += 50;
      await this.updateUser(data);
      console.log(`Award Coins: date: ${new Date(data?.updatedAt).toDateString()} User: ${JSON.stringify(data)}`);
      this.showAwardCoinsAnimation = true;
    }
  }
};
</script>

<style lang='css'>
@import './index.css';
</style>
