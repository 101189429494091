export const brandConfigObjectMap = new Map();

export const Brands = {
    MTNNigeriaEn: 'mtn-ng-en',
    UpstreamAISTh: 'ais-th',
    DemoEn: 'demo-en',
};

brandConfigObjectMap.set(Brands.UpstreamAISTh, {
    baseRoute: '/msisdn',
    productId: 33,
});

brandConfigObjectMap.set(Brands.MTNNigeriaEn, {
    baseRoute: '/login',
    productId: 30,
});


brandConfigObjectMap.set(Brands.DemoEn, {
    baseRoute: '/msisdn',
    productId: 33,
    msisdn: 27845104007
});
