import {brandConfigObjectMap} from "@/brand/brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:3000', getBrandConfig('mtn-ng-en'));
brandConfigMap.set('https://ng.megamatchrival.com', getBrandConfig('mtn-ng-en'));
brandConfigMap.set('https://th.megamatchrival.com', getBrandConfig('ais-th'));
brandConfigMap.set('https://demo.megamatchrival.com', getBrandConfig('demo-en'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        baseRoute: brandConfigObject.baseRoute,
        productId: brandConfigObject.productId,
        msisdn: brandConfigObject.msisdn,
    }
}
