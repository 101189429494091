import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
  upstreamAisHost: `${process.env.VUE_APP_UPSTREAM_AIS_HOST}`,
  depHost: `${process.env.VUE_APP_DEP_HOST}`,
  gameServerHost: `${process.env.VUE_APP_GAME_SERVER_HOST}`,
  websocketHost: `${process.env.VUE_APP_GAME_SERVER_SOCKET_HOST}`,
};

export const stagingConfig = {
  upstreamAisHost: `${process.env.VUE_APP_UPSTREAM_AIS_HOST}`,
  depHost: `${process.env.VUE_APP_DEP_HOST}`,
  gameServerHost: `${process.env.VUE_APP_GAME_SERVER_HOST}`,
  websocketHost: `${process.env.VUE_APP_GAME_SERVER_SOCKET_HOST}`,
};

export const serviceIdToPackageMap = new Map();
serviceIdToPackageMap.set(`default`, `daily`);
serviceIdToPackageMap.set(`4319`, `weekly`);
serviceIdToPackageMap.set(`4244`, `weekly`);
serviceIdToPackageMap.set(`4318`, `daily`);
serviceIdToPackageMap.set(`4243`, `daily`);

export const loginURLPackageMap = new Map();
loginURLPackageMap.set(`weekly`, `http://ng.mycontent.mobi/optin/service/4244?bc_id=56&gv_id=5233`);
loginURLPackageMap.set(`daily`, `http://ng.mycontent.mobi/optin/service/4243?bc_id=56&gv_id=5629`);
loginURLPackageMap.set(`default`, `http://ng.mycontent.mobi/optin/service/4243?bc_id=56&gv_id=5629`);

const environment = getEnvironment();

export const environmentConfig = getEnvObject();

function getEnvironment() {
  let base_url = window.location.origin;
  if (base_url.includes('staging')) return 'staging';
  if (base_url.includes('localhost')) return 'development';
  else return 'production';
}

function getEnvObject() {
  if (environment === 'staging') return stagingConfig;
  if (environment === 'development') return productionConfig;
  if (environment === 'production') return productionConfig;
}
