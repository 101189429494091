<template>
  <div
    v-bind:style="backgroundImage"
    class="align-items-center d-flex flex-column insufficient-main justify-content-center"
  >
    <div
      class="align-items-center insufficient-board-container d-flex flex-column justify-content-start w-75"
    >
      <img :src="coinBag" class="coin-bag" />
      <div class="pb-5 pt-2 text-center" style='width: 70%'>
        <span class="fs-3">
        {{ translationsFormatter('insufficient_funds_header') }}
        </span>
        <hr class="m-2 opacity-100" />
        {{ translationsFormatter('insufficient_coins_text') }}
        <br />
        {{ translationsFormatter('top_up_text') }}
      </div>
    </div>
    <button
      @click="navigateToTopupFunds"
      type="button" v-if="brandConfig.brand !== 'ais-th'"
      class="top-up-btn fs-4 text-light mt-5"
    >
      {{ translationsFormatter('top_up_button') }}
    </button>
    <button @click="navigateToHome" type="button" class="home-btn fs-4 text-light mt-3">
      {{ translationsFormatter('home_button') }}
    </button>
  </div>
</template>

<script>
  import { staticImages, dynamicBg } from '@/constants/imageUrlPath';
  import { mapState } from 'vuex';

  export default {
    name: 'InsufficientFundsUi',
    data() {
      return {
        coinBag: staticImages.coinBag,
      };
    },
    methods: {
      navigateToTopupFunds() {
        const phone = this.$route.query.phone || this.user.msisdn;
        this.$router.push({ name: 'topupfunds', query: { phone } });
      },
      navigateToHome() {
        const phone = this.$route.query.phone || this.user.msisdn;
        this.$router.push({ name: 'home', query: { phone } });
      },
    },
    computed: {
      ...mapState(['brandConfig','user']),
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--board-background': `url(${staticImages['board2']})`,
        };
      }
    }
  };
</script>

<style lang="css">
  @import './index.css';
</style>
