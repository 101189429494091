<template>
  <div v-bind:style='backgroundImage' class='signup-main'>
    <div class='h-25 w-75'>
      <img :src="signUpScreenElements['logo']" alt='' srcset='' class='h-100 w-100' />
    </div>
    <div :class="[textColor, 'fs-5', 'pe-2', 'ps-2', 'text-center', 'text-outline', 'mt-5']">
      {{ translationsFormatter('login_error_text') }}
    </div>
    <div class='d-flex flex-column h-25 w-75'>
      <button
        @click='navigateToLogin()'
        type='button'
        class='btn fs-3 h-50 mt-3 text-light'
      >
        {{ translationsFormatter('login_try_again_text') }}
      </button>
    </div>
    <SpinnerUi v-if='showSpinner' />
  </div>
</template>

<script>
  import SpinnerUi from '../SpinnerUi';
  import { staticImages, dynamicBg } from '@/constants/imageUrlPath';

  export default {
    name: 'LoginErrorUi',
    components: {
      SpinnerUi,
    },
    data() {
      return {
        isButtonDisabled: true,
        showSpinner: false,
        textColor: 'text-black',
        signUpScreenElements: staticImages,
      };
    },
    methods: {
      navigateToLogin() {
        this.$router.push('/login');
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--btn-background': `url(${staticImages['purpleButton']})`,
        };
      },
    },
    mounted() {
      if (this.$DYNAMIC_BG === 'nigeria1' || this.$DYNAMIC_BG === 'nigeria3')
        this.textColor = 'text-white';
    },
  };
</script>

<style lang='css'>
  @import './index.css';

  .text-outline {
    -webkit-text-stroke-width: .15px;
    -webkit-text-stroke-color: #FFFFFF;
  }
</style>
