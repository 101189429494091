<template>
  <div v-bind:style='backgroundImage' class='signup-main'>
    <div class='h-25 w-75'>
      <img :src="signUpScreenElements['logo']" alt='' srcset='' class='h-100 w-100'/>
    </div>
    <div :class="[textColor, 'fs-5', 'pe-2', 'ps-2', 'text-center', 'text-outline', 'mt-5']">
      {{ translationsFormatter('login_info_text') }}
    </div>
    <div @click='termsConditionsClicked()' class='terms-conditions-footer-text col-9 text-center'>
      {{ translationsFormatter(`login_term_conditions_${this.package}_text`) }}
    </div>
    <div class='d-flex flex-column h-25 w-75'>
      <button
          @click='navigateToMyContentMobi'
          type='button'
          class='btn fs-3 h-50 mt-4 text-light'
      >
        {{ translationsFormatter('login_playing_button') }}
      </button>
    </div>
    <SpinnerUi v-if='showSpinner'/>
  </div>
</template>

<script>
import SpinnerUi from '../SpinnerUi';
import {staticImages, dynamicBg} from '@/constants/imageUrlPath';
import {loginURLPackageMap} from "@/config/enviromentConfig";

export default {
  name: 'SignUpScreenUi',
  components: {
    SpinnerUi,
  },
  data() {
    return {
      isButtonDisabled: true,
      showSpinner: false,
      textColor: 'text-black',
      signUpScreenElements: staticImages,
      package: `daily`
    };
  },
  methods: {
    navigateToMyContentMobi() {
      if(loginURLPackageMap.has(this.package)) return window.open(loginURLPackageMap.get(this.package), '_self');
      else return window.open(loginURLPackageMap.get(`default`), '_self');
    },
    termsConditionsClicked() {
      this.$router.push('/terms-and-conditions');
    },
  },
  computed: {
    backgroundImage() {
      return {
        '--background-image': `url(${dynamicBg[0]})`,
      };
    },
  },
  mounted() {
    if (this.$DYNAMIC_BG === 'nigeria1' || this.$DYNAMIC_BG === 'nigeria3')
      this.textColor = 'text-white';
  },
  beforeMount() {
    const user = JSON.parse(localStorage.getItem('userData'));
    this.package = user.package ?? this.package;
  }
};
</script>

<style lang='css'>
@import './index.css';

.terms-conditions-footer-text {
  font-family: Arial;
  font-size: 10px;
  position: fixed;
  bottom: 10px;
}

.text-outline {
  -webkit-text-stroke-width: .15px;
  -webkit-text-stroke-color: #FFFFFF;
}
</style>
